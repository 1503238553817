<template>
  <v-card tile class="mx-2 charcoal" flat>
    <v-card-text v-if="loaded && meals.length == 0" class="silver--text mt-4">
      Nothing to see here. Most likely no data was entered for this day or the
      day has not been closed. Use the energy screen to enter the data or close
      the day.
    </v-card-text>
    <v-card-actions v-else :class="textSizeSmall + ' mb-2 mt-2 pa-0'">
      <v-spacer />
      <v-icon x-large color="paper" @click="previousDay">
        mdi-chevron-left
      </v-icon>
      <span class="paper--text">
        Meals & Nutrition - {{ prettyShortDate(now(offset)) }}
      </span>
      <v-icon x-large color="paper" @click="nextDay">
        mdi-chevron-right
      </v-icon>
      <v-spacer />
    </v-card-actions>

    <template>
      <BaseLoading label="Loading Food Log" v-if="!loaded" class="mt-5" />
      <v-sheet
        v-if="myNutrients != null"
        rounded
        class="transparent  paper--text"
      >
        <NutrientsBlock
          v-if="myNutrients != null"
          :dailyNutrients="myNutrients"
        />
      </v-sheet>
      <v-sheet v-if="loaded" class="transparent charcoalTile">
        <v-timeline v-if="meals.length > 0" align-top :dense="isPhone">
          <v-timeline-item
            color="paper"
            fill-dot
            :left="index % 2 == 0"
            :right="index % 2 != 0"
            v-for="(item, index) in meals"
            :key="item.id"
            :class="isPhone ? 'ml-n7' : ''"
          >
            <template v-slot:icon>
              <v-icon color="charcoal">
                mdi-clipboard-text-clock
              </v-icon>
            </template>
            <template v-slot:opposite>
              <span class="caption silver--text">
                {{ prettyTime(item.meal_time) }}
              </span>
            </template>
            <v-card
              class="charcoal py-2 link px-1 ml-n2"
              :style="charcoalTile"
              rounded
              elevation="2"
            >
              <v-card-actions :class="textSize + ' silver--text py-0'">
                <v-icon color="silver" class="mr-1 ml-n1">
                  mdi-clock
                </v-icon>
                <span title="When">
                  {{ prettyTime(item.meal_time) }}
                </span>
                <v-spacer />
                {{ item.type }}
              </v-card-actions>
              <v-card-actions
                v-if="item.name != 'NA'"
                :class="textSizeSmall + ' paper--text py-0'"
              >
                <span title="Nutrient">
                  {{ item.name }}
                </span>
                <v-spacer />
              </v-card-actions>
              <BasicNutrition :item="item" />
              <v-card-actions class="pb-0 pl-1">
                <v-icon class="ml-n2" color="silver" @click="deleteMeal(item)">
                  mdi-trash-can-outline
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-sheet>
    </template>
  </v-card>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const BasicNutrition = () => import('@/components/blocks/BasicNutrition.vue')
const NutrientsBlock = () => import('@/components/blocks/Nutrients.vue')
export default {
  components: { BasicNutrition, NutrientsBlock },
  name: 'FoodLogDetailsComp',
  mixins: [util],
  props: {
    day: {
      type: String
    },
    show: { type: Boolean }
  },
  watch: {
    show: function(value) {
      if (value) {
        this.refreshPage()
      }
    },
    day: function(value) {
      this.currDay = value
    }
  },
  data() {
    return {
      loaded: false,
      myNutrients: null,
      dailyValues: null,
      offset: 0,
      currDay: null,
      meals: []
    }
  },
  mounted() {
    this.currDay = this.day
    this.refreshPage()
  },
  computed: {
    itemsCount() {
      return this.meals.length
    },
    ...appConfig
  },
  methods: {
    previousDay() {
      this.offset--
      this.currDay = this.now(this.offset)
      this.refreshPage()
    },
    nextDay() {
      this.offset++
      this.currDay = this.now(this.offset)
      this.refreshPage()
    },
    async refreshPage() {
      this.loaded = false
      this.refresh = true
      await this.loadDailyValues()
      await this.loadMeals()
      await this.loadNutrients()
    },
    setResults(value) {
      this.energyList = value
    },
    deleteMeal(item) {
      return axios
        .get(this.baseURL + '/nutrition/delete/meal/' + item.id, {})
        .then(response => {
          if (response.status == 200) {
            this.loadMeals()
            this.toast('Food log entry was succesfully deleted.')
            this.$emit('deleted_meal')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    async loadMeals() {
      return axios
        .get(this.baseURL + '/nutrition/meals/' + this.currDay, {})
        .then(response => {
          if (response.status == 200) {
            this.meals = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    async loadNutrients() {
      return axios
        .get(this.baseURL + '/nutrition/dailynutrients/' + this.currDay, {})
        .then(response => {
          if (response.status == 200) {
            this.myNutrients = response.data.data
            this.myNutrients = { ...this.myNutrients, ...this.dailyValues }
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    async loadDailyValues() {
      return axios
        .get(this.baseURL + '/nutrition/dailyvalues', {})
        .then(response => {
          if (response.status == 200) {
            this.dailyValues = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
